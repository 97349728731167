import "./YearStihList.css";
function Year(props) {

  return (
    <div className="year">
      <p>{props.year}</p>
    </div>
  );
}

export default Year;
